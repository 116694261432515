.label {
    padding-left: 1rem;
    padding-bottom: 0.5rem;
    font-size: 14px;
  }
  
  .combobox {
    height: 34px;
    border-radius: 0.2rem;
    border-style: none;
    padding-left: 1rem;
    outline: none;
    line-height: 1.5rem;
    color: var(--gray-12);
    background-color: var(--accent-a3);
    font-size: 14px;
    width: 100%;
  }

  .transparent {
    background-color: var(--color--main-background) !important;
  }
  
  .combobox::placeholder {
    color: var(--color-main-text-secondary);
  }
  


  .combobox[data-active-item] {
    outline-width: 2px;
  }
  
  .combobox:where(.dark, .dark *) {
    background-color: var(--accent-a3);
    color: var(--gray-12);
    box-shadow:
      inset 0 0 0 1px rgba(255 255 255/0.15),
      inset 0 -1px 0 0 rgba(255 255 255/0.05),
      inset 0 2px 5px 0 rgba(0 0 0/0.15);
    font-size: 14px
  }
  
  .combobox:where(.dark, .dark *)::placeholder {
    color: rgb(255 255 255 / 46%);
  }
  
  .combobox:hover:where(.dark, .dark *) {
    background-color: var(--accent-a3);
  }
  
  .popover {
    position: relative;
    z-index: 50;
    display: flex;
    max-height: min(var(--popover-available-height, 300px), 300px);
    flex-direction: column;
    overflow: auto;
    overscroll-behavior: contain;
    border-radius: 0.2rem;
    border-style: none;
    background-color: var(--gray-3);
    padding: 0.5rem;
    color: var(--gray-12);
    font-size: 14px
  }
  
  
  .combobox-item {
    display: flex;
    cursor: default;
    scroll-margin: 0.5rem;
    align-items: center;
    gap: 0.5rem;
    border-radius: 0.2rem;
    padding: 0.5rem;
    outline: none !important;
    opacity: var(--item-opacity, 1);
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    font-size: 14px
  }
  
  .combobox-item:hover {
    background-color: var(--accent-a3);
    color: var(--accent-12);
  }
  
  .combobox-item[data-active-item] {
    color: var(--accent-12);
  }
  
  .combobox-item:active,
  .combobox-item[data-active] {
    padding-top: 9px;
    padding-bottom: 7px;
  }

  
  .no-results {
    gap: 0.5rem;
    padding: 0.5rem;
  }
  
  .popover[aria-busy="true"] {
    --item-opacity: 0.5;
  }

 
.button {
  display: flex;
  height: 2.5rem;
  user-select: none;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  white-space: nowrap;
  border-radius: 0.5rem;
  border-style: none;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 1rem;

  line-height: 1.5rem;
  color: var(--accent-12);
  text-decoration-line: none;
  outline: none;
}


.button:not(:active):hover {
  --border: rgb(0 0 0/33%);
}


.button[aria-disabled="true"] {
  opacity: 0.5;
}

/* .combobox[data-focus-visible], makes input box border appear on click
.button[data-focus-visible] {
  outline-style: solid;
} */

.button:active,
.button[data-active] {
  padding-top: 0.125rem;
}

@media (min-width: 640px) {
  .button {
    gap: 0.5rem;
  }
}

.secondary {
  background-color: transparent;
  color: currentColor;
  box-shadow: none;
}


.combobox-wrapper {
  position: relative;
}

.disclosure {
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  height: 2rem;
  width: 2rem;
  border-radius: 0.125rem;
  padding: 0px;
}

  