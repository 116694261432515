.DialogOverlay {
  position: fixed;
  inset: 0px;
  z-index: 50;
  background-color: rgb(0 0 0 / 0.4);
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.DialogOverlay[data-state="closed"] {
  opacity: 0;
}

.DialogContent {
  position: fixed;
  inset: 0.75rem;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 50;
  margin: auto;
  display: flex;
  height: fit-content;
  max-height: calc(100vh - 2 * 0.75rem);
  max-width: 28rem;
  flex-direction: column;
  gap: 1rem;
  overflow: auto;
  border-radius: 0.75rem;
  background-color: var(--accent-3);
  padding: 1.5rem;
  color: var(--acent-12);
  transition-property: opacity, transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
  color-scheme: light;
}

.DialogContent[data-state="closed"] {
  opacity: 0;
  transform: translateY(8px) scale(0.96);
}

.DialogTitle {
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 500;
}

.DialogDescription {
  color: rgb(0 0 0 / 0.6);
}
