.clearable-button {
    --border: rgb(0 0 0/13%);
    --highlight: rgb(255 255 255/20%);
    display: flex;
    height: 32px;
    user-select: none;
    align-items: center;
    border-radius: 3;
    gap: 0.25rem;
    white-space: nowrap;
    border-style: none;
    background-color: var(--accent-a3);
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 0.5px;
    line-height: 1.5rem;
    font-size: 90%;
    color: var(--accent-12);
    text-decoration-line: none;
    outline-width: 2px;
    outline-offset: 2px;
    width: 100%;
    justify-content: space-between;
    overflow-x: hidden;
  }
  
  .clearable-button:not(:active):hover {
    --border: rgb(0 0 0/33%);
  }
  
  .clearable-button[aria-disabled="true"] {
    opacity: 1;
  }
  
  .clearable-button[data-focus-visible] {
    outline-style: solid;
  }
  
  .clearable-button:active,
  .clearable-button[data-active] {
    padding-top: 0.125rem;
    box-shadow:
      inset 0 0 0 1px var(--border),
      inset 0 2px 0 var(--border);
  }
  
  @media (min-width: 640px) {
    .clearable-button {
      gap: 0.5rem;
    }
  }
  
  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem;
  }
  
  .label {
    padding-left: 1rem;
  }
  
  .popover {
    z-index: 50;
    display: flex;
    max-height: min(var(--popover-available-height, 300px), 300px);
    flex-direction: column;
    overflow: auto;
    overscroll-behavior: contain;
    background-color: var(--gray-3);
    padding: 0.5rem;
    color: var(--accent-12);
    font-size: 90%;
  }
  
  .popover:focus-visible,
  .popover[data-focus-visible] {
    outline: 2px solid var(--gray-3);
    outline-offset: -1px;
  }
  
  .select-item {
    display: flex;
    cursor: default;
    scroll-margin: 0.5rem;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem;
    outline: none !important;
  }
  
  .select-item[aria-disabled="true"] {
    opacity: 1;
  }
  
  .select-item[data-active-item] {
    background-color: var(--accent-a3);
    color: var(--accent-12);
  }
  